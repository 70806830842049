import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { NotificationContainer } from 'react-notifications';

import asyncComponent from '../components/async';
import NavigationContainer from './navigation-container';
import Index from '../components';
import { getAllNetworksFromAPI } from '../services/networks';

const AsyncLoginContainer = asyncComponent(() =>
  import('../components/login-form'),
);
const AsyncEvaluateContainer = asyncComponent(() =>
  import('./evaluate-container'),
);
const AsyncNetworkBuilderContainer = asyncComponent(() =>
  import('./network-builder-container'),
);
const AsyncNetworkSimulateContainer = asyncComponent(() =>
  import('./network-simulate-container'),
);
const AsyncNetworkContainer = asyncComponent(() =>
  import('./network-container'),
);
const Async404 = asyncComponent(() => import('../components/error-404'));

const { bool, shape } = PropTypes;

class MainContainer extends Component {
  static propTypes = {
    user: shape({
      isAuthed: bool.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.getNetworks = this.getNetworks.bind(this);
  }

  async getNetworks() {
    const rawNetworks = await getAllNetworksFromAPI();
    const networks = {};
    rawNetworks.data.map(item => {
      networks[item.id] = {
        name: item.name,
        version: item.version,
      };
      return networks;
    });
    return networks;
  }

  render() {
    return (
      <div className="main-container">
        <NotificationContainer />
        {this.props.user.isAuthed ? (
          <Router>
            <Fragment>
              <NavigationContainer getNetworks={this.getNetworks} />

              <div className="container">
                <Switch>
                  <Route exact path="/" component={Index} />
                  <Route
                    path="/evaluate-network/:networkID"
                    component={AsyncEvaluateContainer}
                  />
                  <Route
                    path="/build-network/"
                    component={AsyncNetworkBuilderContainer}
                  />
                  <Route
                    path="/network/:networkID"
                    component={AsyncNetworkContainer}
                  />
                  <Route
                    path="/simulate-network/"
                    component={AsyncNetworkSimulateContainer}
                  />
                  <Route component={Async404} />
                </Switch>
              </div>
            </Fragment>
          </Router>
        ) : (
          <AsyncLoginContainer />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(MainContainer);
